import styled from "styled-components"

import { MonitoringPopup } from "src/components/Homes/HomeDetails/MonitoringPopup"
import { Violations } from "src/data/homes/types/homeTypes"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { colors } from "src/ui/colors"
import FireCoOnIcon from "src/ui/icons/fire-co-on.svg"
import HomeIcon from "src/ui/icons/home.svg"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function FireCOAlarmButtonPopover({
  open,
  anchorEl,
  handleClose,
  violations,
  automatedTestsOn,
}: {
  open: boolean
  handleClose: () => void
  anchorEl: null | HTMLElement
  violations: Violations[] | undefined
  automatedTestsOn: boolean
}) {
  const { t } = useTranslate()

  const description = t(langKeys.fire_and_co_short_description)

  function onClose() {
    handleClose()
  }

  function OnIcon() {
    return <FireCoOnIcon height="56" />
  }

  return (
    <MonitoringPopup
      open={open}
      error={false}
      anchorEl={anchorEl}
      onClose={onClose}
      title={t(langKeys.fire_and_co_alarm_title)}
      description={description}
      warning={t(langKeys.update_your_plan)}
      violations={violations}
      buttons={[
        {
          icon: <OnIcon />,
          onClick: () => onClose(),
          label: <div>{t(langKeys.on)}</div>,
          disablePointerEvents: true,
          disableOnWarning: true,
          id: "fire-co-on",
        },
      ]}
      subtitle={
        <PopoverSubtitle>
          <StyledHomeIcon width="24px" height="24px" />
          {t(langKeys.home_indoor_label)}
        </PopoverSubtitle>
      }
    >
      <SettingsBox>
        <Section>
          <MText variant="subtitle">
            <span>{t(langKeys.fire_and_co_alarm_automated_tests_title)}</span>{" "}
            <AutomatedTestsPill on={automatedTestsOn} />
          </MText>

          <MText variant="bodyS" color="secondary">
            {automatedTestsOn
              ? t(langKeys.fire_and_co_automated_tests_on_short_description)
              : t(langKeys.fire_and_co_automated_tests_off_short_description)}
          </MText>
        </Section>

        <Section>
          <MText variant="bodyS" color="secondary">
            {t(langKeys.fire_and_co_control_screen_footer)}
          </MText>
        </Section>
      </SettingsBox>
    </MonitoringPopup>
  )
}

const SettingsBox = styled.div`
  display: grid;
  grid-gap: ${spacing.XL};
  padding-top: ${spacing.S};
`

const Section = styled.section`
  display: grid;
  gap: ${spacing.M};
  border-top: 1px solid ${colors.divider};
  padding-top: ${spacing.L};
`

const PopoverSubtitle = styled.div`
  display: inline-flex;
  align-items: center;
`

const StyledHomeIcon = styled(HomeIcon)`
  margin-right: ${spacing.XS};
`

function AutomatedTestsPill({ on }: { on: boolean }) {
  const { t, langKeys } = useTranslate()
  if (on) {
    return <MBadge>{t(langKeys.on)}</MBadge>
  }
  return <MBadge color="neutral">{t(langKeys.off)}</MBadge>
}
