import styled from "styled-components"

import { IndoorClimateSafetyAlerts } from "src/components/Homes/HomeDetails/IndoorClimate/IndoorClimateSafetyAlerts"
import { IndoorClimateSettings } from "src/components/Homes/HomeDetails/IndoorClimate/IndoorClimateSettings"
import { IndoorClimateToggleActiveIcon } from "src/components/Homes/HomeDetails/IndoorClimate/IndoorClimateToggleActiveIcon"
import { MonitoringPopup } from "src/components/Homes/HomeDetails/MonitoringPopup"
import { usePostIndoorClimateToggled } from "src/data/analytics/queries/homeOverviewCardsAnalyticsQueries"
import { TIndoorClimateMonitoring } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { usePatchIndoorClimateMonitoring } from "src/data/organizations/queries/homeQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { Divider } from "src/ui/Divider/Divider"
import HomeIcon from "src/ui/icons/home.svg"
import { MBanner } from "src/ui/MBanner/MBanner"
import { spacing } from "src/ui/spacing"

export function IndoorClimatePopover({
  homeId,
  indoorClimateMonitoring,
  anchorEl,
  onClose,
}: {
  homeId: string
  indoorClimateMonitoring: TIndoorClimateMonitoring | undefined
  anchorEl: HTMLElement | null
  onClose: () => void
}) {
  const { t, langKeys } = useTranslate()
  const { orgId } = useOrganization()

  const enabled = indoorClimateMonitoring?.state === "enabled"

  const patchIndoorClimateMonitoring = usePatchIndoorClimateMonitoring({
    orgId,
  })

  const postIndoorClimateToggled = usePostIndoorClimateToggled()

  function handleTurnOnClick() {
    patchIndoorClimateMonitoring.mutate(
      {
        homeId,
        data: { state: "enabled" },
      },
      {
        onSuccess: (vars) => {
          postIndoorClimateToggled.mutate({
            new_toggle_status: vars.state,
            temperature_enabled: !!indoorClimateMonitoring?.temperature_enabled,
            humidity_enabled: !!indoorClimateMonitoring?.humidity_enabled,
          })
          onClose()
        },
      }
    )
  }

  function handleTurnOffClick() {
    patchIndoorClimateMonitoring.mutate(
      {
        homeId,
        data: { state: "disabled" },
      },
      {
        onSuccess: (vars) => {
          postIndoorClimateToggled.mutate({
            new_toggle_status: vars.state,
            temperature_enabled: !!indoorClimateMonitoring?.temperature_enabled,
            humidity_enabled: !!indoorClimateMonitoring?.humidity_enabled,
          })

          onClose()
        },
      }
    )
  }

  const buttons = [
    {
      label: t(langKeys.off),
      id: "off",
      onClick: handleTurnOffClick,
      icon: (
        <IndoorClimateToggleActiveIcon
          toggleState="off"
          state={indoorClimateMonitoring?.state || "disabled"}
          iconProps={{ height: "56" }}
        />
      ),
      disablePointerEvents: !enabled,
    },
    {
      label: t(langKeys.on),
      id: "on",
      onClick: handleTurnOnClick,
      icon: (
        <IndoorClimateToggleActiveIcon
          toggleState="on"
          state={indoorClimateMonitoring?.state || "disabled"}
          iconProps={{ height: "56" }}
        />
      ),
      disableOnWarning: true,
      disablePointerEvents: enabled,
    },
  ]

  return (
    <MonitoringPopup
      open={true}
      title={t(langKeys.indoor_climate)}
      anchorEl={anchorEl}
      description={t(langKeys.indoor_climate_dashboard_card_description)}
      onClose={onClose}
      buttons={buttons}
      error={false}
      subtitle={
        <div>
          <StyledHomeIcon width="24px" height="24px" />
          {t(langKeys.home_indoor_label)}{" "}
        </div>
      }
    >
      <Grid>
        {patchIndoorClimateMonitoring.isError && (
          <MBanner type="error" fullWidth>
            {`${t(langKeys.failed_something_went_wrong)}`}
          </MBanner>
        )}

        <Divider />
        <IndoorClimateSettings
          homeId={homeId}
          indoorClimateMonitoring={indoorClimateMonitoring}
        />
        <Divider />
        <IndoorClimateSafetyAlerts />
      </Grid>
    </MonitoringPopup>
  )
}

const StyledHomeIcon = styled(HomeIcon)`
  margin-right: ${spacing.XS};
`

const Grid = styled.div`
  display: grid;
  margin-top: ${spacing.L};
  gap: ${spacing.M};
`
